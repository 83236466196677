import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/fittext-logo.svg';
import menuarrow from './assets/green-arrowmenu.svg';

// Import local SVG icons
import FBSIcon from './assets/fbsmenu_icon.svg';
import FMSIcon from './assets/fmsmenu_icon.svg';
import FASIcon from './assets/fasmenu_icon.svg';
import HRMIcon from './assets/hrmmenu_icon.svg';
import SCMIcon from './assets/fmsmenu_icon.svg';
import COMIcon from './assets/scmmenu_icon.svg';
import MMSIcon from './assets/mmsicon.svg';
import CRMIcon from './assets/crmicon.svg';
import POSIcon from './assets/posicon.svg';
// import TASKSIcon from './assets/taksicn.svg';

const Icons = {
  FBS: FBSIcon,
  FMS: FMSIcon,
  FAS: FASIcon,
  HRM: HRMIcon,
  SCM: SCMIcon,
  COM: COMIcon,
  MMS: MMSIcon,
  CRM: CRMIcon,
  POS: POSIcon,
  // TASKS: TASKSIcon,
};

const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    setActiveCategory(dropdown === 'modules' ? 'manufacturing' : null);
  };

  const setCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const categories = {
    manufacturing: {
      title: "Branding & Sourcing",
      items: [
        { code: "MMS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/mms" },
        { code: "FAS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fas" },
        { code: "CRM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/crm" },
        // { code: "FBS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fbs" },
        // { code: "FMS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fms" },
        // { code: "FAS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fas" },
        // { code: "HRM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/hrm" },
      ]
    },
    branding: {
      title: "Factory Suite",
      items: [
        { code: "MMS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/mms" },
        { code: "FBS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fbs" },
        { code: "FMS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fms" },
        { code: "FAS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fas" },
        { code: "CRM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/crm" },
        // { code: "SCM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/scm" },
        // { code: "COM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/com" },
        // { code: "HRM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/hrm" },
      ]
    },
    brandOffices: {
      title: "Supply Chain Management",
      items: [
        { code: "SCM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/scm" },
        { code: "FAS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/fas" },
        { code: "COM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/com" },
        { code: "POS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/pos" },
        { code: "CRM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/crm" },
      ]
    },
    customer: {
      title: "Business Collaboration",
      items: [
        { code: "CRM", description: "Sed dignissim, metus nec fringilla accumsan", link: "/crm" },
        { code: "FILE", description: "Sed dignissim, metus nec fringilla accumsan", link: "/filemangement" },
        { code: "TASKS", description: "Sed dignissim, metus nec fringilla accumsan", link: "/tasks" },
      ]
    },
  };

  return (
    <div className='bg-[#ffffff] navbar fixed top-0 left-0 right-0 z-50'>
      <div className='flex justify-between items-center px-10 h-[86px]'>

        {/* Left - Logo */}
        <div className='w-1/3 flex justify-start'>
          <Link to="/"><img src={logo} alt='Logo' /></Link>
        </div>

        {/* Center - Menu Items */}
        <div className='w-1/3 flex justify-center space-x-10'>

          {/* Products */}
          <div className='relative'>
            <div className='text-[20px] text-[#121212] Quicksand cursor-pointer flex items-center' onClick={() => toggleDropdown('modules')}>
              Solutions
              <span className='ml-2'>&#9662;</span>
            </div>
            {openDropdown === 'modules' && (
              <div className='absolute w-[1107px] h-[466px] bg-white rounded-[7px] mt-[47px] flex shadow-lg'>
                <div className='w-1/3 p-4'>
                  {Object.keys(categories).map((key) => (
                    <div
                      key={key}
                      className={`flex items-center text-[18px] cursor-pointer mb-[33px] Quicksand ${activeCategory === key ? 'text-[#1CB584]' : 'text-[#121212]'}`}
                      onClick={() => setCategory(key)}
                    >
                      {categories[key].title}
                      {activeCategory === key && <span className='ml-2'><img src={menuarrow} alt='Arrow'/></span>}
                    </div>
                  ))}
                  <button className='px-4 py-2 bg-[#474747] text-white rounded Quicksand mt-4'>EXPLORE ALL PRODUCTS</button>
                </div>

                {/* Submenu Content */}
                {activeCategory && (
                  <div className='w-[665px] p-4 bg-[#F0F0F0] h-[423px] rounded-[17px] mt-[23px]'>
                    <div className='text-[24px] text-[#121212] GinóraSemiBold mb-4'>{categories[activeCategory].title}</div>
                    <div className='grid grid-cols-2 gap-[43px] mt-[39px]'>
                      {categories[activeCategory].items.map((item, index) => (
                        <Link to={item.link} className='flex items-center' key={index}>
                          <img src={Icons[item.code]} alt={item.code} className='w-[42px] h-[44px] mr-2 mt-[-14px]' />
                          <div>
                            <div className='text-[#121212] GinóraReqular text-[24px]'>{item.code}</div>
                            <div className='text-[#7D7D7D] text-[14px] GinóraReqular'>{item.description}</div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Customers */}
          <Link to="/customer" className='text-[20px] text-[#121212] Quicksand cursor-pointer flex items-center'>
            Customers
          </Link>

          {/* Pricing */}
          <div className='text-[21px] text-[#121212] Quicksand'>Pricing</div>

          {/* Resources */}
          <div className='relative'>
            <div className='text-[20px] text-[#121212] Quicksand cursor-pointer flex items-center' onClick={() => toggleDropdown('resources')}>
              Resources
              <span className='ml-2'>&#9662;</span>
            </div>
            {openDropdown === 'resources' && (
              <div className='absolute mt-2  bg-white shadow-md rounded w-[189px] h-[279px] p-[41px]'>
                <Link to="/carrier"><div className='p-2 text-[18px] text-[#121212] Ginóra'>Careers</div></Link>
                <Link to="/blogs"><div className='p-2 text-[18px] text-[#121212] Ginóra'>Blogs</div></Link>
                <Link to="/carrier"><div className='p-2 text-[18px] text-[#121212] Ginóra'>Tech DOC</div></Link>
                <Link to="/partners"><div className='p-2 text-[18px] text-[#121212] Ginóra'>Partners</div></Link>
              </div>
            )}
          </div>

          {/* Contact */}
          <div className='text-[21px] text-[#121212] Quicksand'>Contact</div>
        </div>

        {/* Right - Buttons */}
        <div className='w-1/3 flex justify-end space-x-8'>
          <button className='text-[21px] px-5 py-1 bg-black text-white rounded-[8px] Quicksand'>Book a Demo</button>
          <button className='text-[21px] px-5 py-1 border border-[#121212] text-[#121212] rounded-[8px] Quicksand'>Login</button>
        </div>

      </div>
    </div>
  );
};

export default Navbar;
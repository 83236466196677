import React from 'react'
import jeevan from './assets/jeevanlogo.svg';
import insta from './assets/instagram.svg';
import xfact from './assets/xfactor.svg';
import bein from './assets/bein.svg';
import linked from './assets/linkedin.svg';
import youtube from './assets/yotube.svg';
const Footer = () => {
  return (
    <div className='footer'>
      
    <div className='flex flex-row w-full h-[371px] bg-[#FAFAFA]'>
        <div className='flex flex-col w-[30%] '>
          <img src={jeevan} className='w-[119.7px] h-[41px] ml-[414px] mt-[94px] ftrsection1'/>
          <div className='text-[17px] Ginóra_Bold ml-[414px] text-[#0D0D0D] mt-[24px] ftrsection1'>Fittex</div>
          <div className='text-[17px] Ginóra ml-[414px] text-[#0D0D0D] mt-[24px] ftrsection1'>Fit Onebox</div>
          <div className='text-[17px] Ginóra ml-[414px] text-[#0D0D0D] mt-[24px] ftrsection1  '>Cambli</div>

        </div>
        <div className='flex flex-col w-[70%] '>
          <div className='flex flex-row ml-[247px]'>
            <div className='w-[20%] h-[147px] '>
              <div className='text-[20px] text-[#0D0D0D] mt-[94px] Ginóra _Bold'>Fittex</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Resources</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Modules</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Customers </div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Pricing </div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Contact</div>

            </div>
            <div className='w-[30%] h-[147px] '>
            <div className='text-[20px] text-[#0D0D0D] mt-[94px] Ginóra _Bold'>Products</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Factory Business suite</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Supply Chain Management</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Merchandising Management System</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Customer Relation Manager</div>
              
            </div>
            <div className='w-[20%] h-[147px] '>
            <div className='text-[20px] text-[#0D0D0D] mt-[94px] Ginóra _Bold'>Contact</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Get started</div>
              <div className='text-[16px] text-[#0D0D0D] Ginóra mt-[18px]'>Request a DEMO</div>
              
            </div>
            <div className='w-[20%] h-[147px] '>
            <div className='text-[20px] text-[#0D0D0D] mt-[94px] Ginóra _Bold'>Connect with us</div>
            <div className='flex gap-5 mt-[18px]'>
              <img src={insta}/>
              <img src={xfact}/>
              <img src={bein}/>
              <img src={linked}/>
              <img src={youtube}/>
            </div>

            </div>

          </div>
        </div>
    </div>
    <div className='w-[1668px] h-[1px] flex mx-auto bg-black mb-[44px]'></div>
      <div className='flex flex-row gap-5 mx-auto items-center justify-center mb-[22px]'>
        <div className='flex flex-col text-[#212121] text-[12px]'>© All rights reserved. Jeevan Infotech</div>
        <div className='flex flex-col text-[#212121] text-[12px]'>Terms of service</div>
        <div className='flex flex-col text-[#212121] text-[12px]'>Privacy</div>

      </div>
</div>
  )
}

export default Footer

import React from 'react'
import Navbar from './navbar'
import mmswave1 from './assets/mmswave.svg'
import crmlogo from './assets/crmlogo.svg'
import TabSection from './mmstab';
import crmtasks from './assets/crmbanner.svg'
import whitearrowcircle from './assets/whitearrow_circle.svg'
import whitearrow from './assets/whiterrow.svg'
import keywheel1 from './assets/key-wheel.svg'
import keytick from './assets/key-tick.svg'
import hashline from './assets/hashlinewider.svg'
import leadsdashboard from './assets/leads_dashboard.svg'
import plreportdk1 from './assets/prosepectsbg.svg'
import prospects from './assets/prospects.svg'
import crmdashboard from './assets/crmdashboard.svg'
import bulkcontact from './assets/bulkcontact.svg'
import followup from './assets/followup.svg'
import activityreport from './assets/activityreport.svg'
import sheet from './assets/jobdashboard.svg'
import jobsheet from './assets/sheetentry.svg'
import sheetinvoice from './assets/sheetinvoice.svg' 
import Payement from './assets/payement.svg'
import stylepostion from './assets/stylepsition_crm.svg'
import filecreate from './assets/file_creation.svg'
import ticketdash from './assets/tickets.svg'
import OpenTickets from './assets/opentickets.svg'
import taskallocation from './assets/task-allocation.svg'
import TaskUpdate from './assets/task-updated.svg'
import crmbanner1 from './assets/crmnew.png'
import blackarrow from './assets/fbs_arrow.svg'
import Footer from './footer';
export default function CrmNe() {

    const mmswave = {
        backgroundImage: `url(${mmswave1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    
      };


      const plreportdk = {
        backgroundImage: `url(${plreportdk1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
    
      };

      const crmbanner = {
        backgroundImage: `url(${crmbanner1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    
      };


  return (
    <div className='main overflow-x-hidden'>
       <div>
        <Navbar/>
       </div>


       <div className='conatiner'>
      <div
  className="flex flex-row w-full h-[378px] items-center px-8 py-4 merchandising"
  style={mmswave}
>
  {/* Left Section with Logo and Text */}
  <div className="flex flex-row items-center w-[50%] ml-[98px] mt-[-17px]">
    {/* Logo */}
    <div className="flex-shrink-0">
      <img src={crmlogo} className="w-[151px] h-[118px]" alt="Logo" />
    </div>
    {/* Text */}
    <div className="ml-[8px]">
      <div className="text-[32px] Redhatbold text-black">Customer Relation</div>
      <div className="text-[32px] RedHatDisplayMedium text-[#171717] mt-[-11px]">
      & Beyond
      </div>
    </div>
  </div>

  {/* Right Section with Fixed Tabs */}
  <div className="flex w-[50%] justify-end items-center">
    <div
      className="fixed right-1 top-16 z-40" // Adjust the position here as needed
      style={{ width: '300px' }} // Set a width to prevent layout issues
    >
      <TabSection/>
    </div>
  </div>
</div>
      </div>





      <div className='section1 w-full h-[112px] mt-[-147px]' >
        <div className='text-[73px] text-[#323232] flex items-center justify-center tracking-[-0.01em] RedHatDisplayMedium mt-[478px]'>A tailor-made <span className='ml-[21px] gradient-effective Redhatbold tracking-[-0.01em]'>Monitoring </span></div>
        <div className='w-[761.14px] flex text-center items-center justify-center mx-auto text-[21px] GinóraSemiBold text-[#202020] mt-[-12px] tracking-[-0.01em]'>dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan</div>
        <div className='flex '>
                <div className='text-[20px] Kollektif-Regular font-normal text-white w-[157px] h-[44px] bg-[#474747] rounded-[7px] mt-[48px] flex items-center justify-center '>See pricing <span><img src={whitearrow} className='pl-[12px]'/></span></div>
                <div className='text-[20px] Ginóra _Bold text-[#2B2B2B] mt-[48px] ml-[28px] flex items-center justify-center'>Request a Demo</div>
                <img src={whitearrowcircle} className='flex items-center justify-center pt-[48px] ml-[17px]'/>
            </div>

      </div>

      <div>
        <img src={crmtasks} className='w-[1401.24px] h-[829.66px] mt-[317px] flex mx-auto crmtasks'/>
      </div>



      <div className='flex flex-row container mx-auto w-[100%] h-[418px] keysection mt-[347px]'>
    <div className='w-[40%] h-[418px] '>
    <img src={keywheel1} className='z-40'/>
        <div className='text-[42px] Redhatsemibold text-black ml-[147px] mt-[-147px]' >key Features</div>
        <div className='quicksand-medium text-[15px] text-[#202020] w-[397px] ml-[147px]'>
          Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus sollicitudin lacus, ut interdum tellus elit sed risus. 
        </div>
    </div>
    <div className='w-[54%]  h-[418px] bg-[#FAFAFA] border border-[#E0E0E0] rounded-[28px] '>
       <div className='flex flex-row'>
       <div className="w-full h-[417px] p-[47px]">
          <ul className="space-y-4">
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
              <span className="mr-2"><img src={keytick}/></span> Bulk Contact Management
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Leads, Prospects & Follow-up
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Lead Tracking & Monitoring
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Customer Support Tickets
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Ticket Lifecycle Automation
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span>Internal Task Flow Management
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> File & Documents Management
            </li>
          </ul>
        </div>



            {/* Slate Box */}
            <div className="w-full h-[417px] p-[47px]">
          <ul className="space-y-4 ">
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Service Job Cards & Billing
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Visitor Register Management
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Bulk Contacts Import / Export
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Web Panels (Customer & Agency)
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Dashboard & Analytics
            </li>
         
          </ul>
        </div>



       </div>
    </div>
  </div>
      

  

  <img src={hashline} className='flex mx-auto mt-[147px]'/>
       

       <div className='section2 mt-[41px]'>
         <div className='text-[43px] text-[#1A1A1A] w-[786px] flex mx-auto items-center justify-center text-center leading-[104%] CirceBold mt-[181px] tracking-[-0.01em]'>We’re on a mission to help understand with each concept.</div>
        <div className='flex items-center justify-center mx-auto text-center w-[822px] mt-[18px] GinóraReqular'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        </div>

        <div className='maindashboard w-full h-[971.19px] mt-[147px]' style={plreportdk}>
          <div className='flex text-center text-[48px] Ginórabold mx-auto justify-center items-center pt-[71px]'>Main Dashboard</div>
          <img src={crmdashboard} className='flex mx-auto'/>
          <div className='GinóraRequla text-[22px] text-[#1A1A1A] flex mx-auto w-[1238px] text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
          </div>
        </div>
      
       </div>


       <div className='leads'>
            <div className='text-[48px] GinóraSemiBold text-[#202020] text-center flex mx-auto items-center justify-center pt-[148px]'>Leads Status Dashboard</div>
            <img src={leadsdashboard} className='flex mx-auto mt-[71px]'/>
            <div className='flex items-center justify-center mx-auto text-center w-[1422px] mt-[18px] GinóraReqular  text-[22px] text-[#1A1A1A]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        </div>
        </div>






        <div className='activityreport conatiner mx-auto max-w-[1920px] h-[618px] mt-[118px] pt-[74px] pb-[74px]'style={plreportdk} >
        <div className='flex flex-row w-[84%] mx-auto'>
         <div className='flex flex-col'>
             <img src={prospects} className='w-full h-[503px] mt-[-74px]'/>
         </div>
         <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[88px] ml-[94px]'>Prospects</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
        </div>
        </div>


        <div className=' mx-auto'>
       <div className='voucherbg container w-[89%] mx-auto'>
        <div className='flex flex-row mt-[139px]'>
         
         <div className='flex flex-col w-[60%] h-474px] ' >
            <div className='text-[48px] text-[#202020] GinóraSemiBold tracking-[-0.02em] mt-[118px] ml-[175px] vouchertxt vouchertxt1'>Bulk Contact Posting</div>
            <div className='Ginóra text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[175px] GinóraReqular vouchertxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
         <div className='flex flex-col w-[50%] h-[474px] mt-[39px]'>
            <img src={bulkcontact} className='mr-[65px] voucherright w-[724px] h-[402px]'/>
         </div>
        </div>
        </div>
       </div>



       <div className='w-full h-[739px] mx-auto' style={plreportdk} >
       <div className='voucherbg container w-[89%] mx-auto'>
        <div className='flex flex-row mt-[139px]'>
         
         <div className='flex flex-col w-[60%] h-474px] ' >
            <div className='text-[48px] text-[#202020] GinóraSemiBold tracking-[-0.02em] mt-[118px] ml-[175px] vouchertxt vouchertxt1'>Follow Up</div>
            <div className='Ginóra text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[175px] GinóraReqular vouchertxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
         <div className='flex flex-col w-[50%] h-[474px] mt-[39px]'>
            <img src={followup} className='mr-[65px] voucherright w-[724px] h-[402px]'/>
         </div>
        </div>
        </div>
       </div>



       {/* <div className='six'>
        <div className='flex flex-row mt-[139px]'>
         
         <div className='flex flex-col w-[50%] h-474px] '>
            <div className='text-[48px] text-[#202020] GinóraSemiBold tracking-[-0.02em] mt-[118px] ml-[175px] filertxt'>Activity Report</div>
            <div className='Ginóra text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[175px] filertxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
         <div className='flex flex-col w-[50%] h-[474px] '>
            <img src={activityreport} className=''/>
         </div>
        </div>
        </div> */}

     


        <div className='activityreport conatiner mx-auto max-w-[1880px] pt-[74px] pb-[74px]'>
        <div className='flex flex-row w-[84%] mx-auto'>
         <div className='flex flex-col'>
             <img src={activityreport} className='w-full h-[503px]'/>
         </div>
         <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[118px] ml-[94px]'>Activity Report</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
        </div>
        </div>




        <div className='w-full h-[739px] mt-[121px]'  style={plreportdk} >
         <div className='text-[48px] text-[#202020] GinóraSemiBold flex mx-auto items-center justify-center pt-[74px]'>Job Sheet Dashboard</div>
           <div><img src={sheet} className='flex mx-auto items-center justify-center '/></div>
           <div className='w-[992px] flex items-center justify-center mx-auto text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
           </div>
           </div>


           {/* <div className='section2 w-full h-[739px]'>
        <div className='flex flex-row mt-[139px]'>
         <div className='flex flex-col w-[50%] h-[474px] '>
            <img src={jobsheet} className='ml-[165px] mt-[79px]'/>
         </div>
         <div className='flex flex-col w-[50%] h-474px] '>
            <div className='text-[48px] text-[#202020] GinóraSemiBold tracking-[-0.02em] mt-[118px] ml-[94px]'>Job Sheet Entry</div>
            <div className='Ginóra text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
        </div>
        </div> */}

        <div className='jobentry conatiner mx-auto max-w-[1880px] pt-[74px] pb-[74px]'>
        <div className='flex flex-row w-[75%] mx-auto'>
         <div className='flex flex-col'>
             <img src={jobsheet}/>
         </div>
         <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[48px] ml-[94px]'>Job Sheet Entry</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
        </div>
        </div>



        <div className='activityreport conatiner mx-auto max-w-[1920px] h-[618px] mt-[118px] pt-[74px] pb-[74px]'style={plreportdk} >
        <div className='flex flex-row w-[84%] mx-auto'>

        <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[88px] ml-[94px]'>Job Sheet invoice</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>

         <div className='flex flex-col'>
             <img src={sheetinvoice} className='w-full h-[503px] mt-[-74px]'/>
         </div>

        </div>
        </div>


      

        <div className='payementconatiner mx-auto max-w-[1880px] pt-[74px] pb-[74px]'>
        <div className='flex flex-row w-[75%] mx-auto'>
         <div className='flex flex-col'>
             <img src={Payement}/>
         </div>
         <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[48px] ml-[94px]'>Payement</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
        </div>
        </div>





    
        <div className='stylepostion conatiner mx-auto max-w-[1920px] h-[618px] mt-[118px] pt-[74px] pb-[74px]'style={plreportdk} >
        <div className='flex flex-row w-[84%] mx-auto'>
         
        <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[88px] ml-[94px]'>Style Position</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>

         <div className='flex flex-col'>
             <img src={stylepostion} className='w-full h-[503px] ml-[47px]'/>
         </div>

        </div>
        </div>



        <div className='filecreate conatiner mx-auto max-w-[1880px] pt-[74px] pb-[74px]'>
        <div className='flex flex-row w-[75%] mx-auto'>
         <div className='flex flex-col'>
             <img src={filecreate}/>
         </div>
         <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[48px] ml-[94px]'>File Creation</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>
        </div>
        </div>



        <div className='ticketdash conatiner mx-auto max-w-[1920px] h-[618px] mt-[118px] pt-[74px] pb-[74px]'style={plreportdk} >
        <div className='flex flex-row w-[84%] mx-auto ticketdasboard'>
         
        <div className='flex flex-col'>
             <img src={ticketdash} className='w-full h-[503px] ml-[47px]'/>
         </div>

        <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[88px] ml-[94px]'>Tickets Dashboard</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>

        </div>
        </div>


      
        <div className='OpenTickets conatiner mx-auto max-w-[1920px] h-[618px] mt-[118px] pt-[74px] pb-[74px]' >
        <div className='flex flex-row w-[84%] mx-auto'>
         
        <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[88px] ml-[94px]'>Open Tickets</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>

         <div className='flex flex-col'>
             <img src={OpenTickets} className='w-full h-[503px] ml-[47px]'/>  
         </div>

        </div>
        </div>




        <div className='taskallocation conatiner mx-auto max-w-[1920px] h-[618px] mt-[118px] pt-[74px] pb-[74px]'style={plreportdk} >
        <div className='flex flex-row w-[84%] mx-auto ticketdasboard'>
         
        <div className='flex flex-col'>
             <img src={taskallocation} className='w-full h-[503px] ml-[47px]'/>
         </div>

        <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[88px] ml-[94px]'>Task Allocation</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>

        </div>
        </div>
     


    
        <div className='Task Update conatiner mx-auto max-w-[1920px] h-[618px] mt-[118px] pt-[74px] pb-[74px]' >
        <div className='flex flex-row w-[84%] mx-auto'>
         
        <div className='flex flex-col'>
         <div className='text-[48px] text-[#202020] Redhatbold tracking-[-0.02em] mt-[88px] ml-[94px]'>Task Update</div>
         <div className='quicksand-medium text-[20px] text-[#202020] w-[613.32px] leading-[151%] tracking-[0.01em] mt-[18px] ml-[94px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit</div>
         </div>

         <div className='flex flex-col'>
             <img src={TaskUpdate} className='w-full h-[503px] ml-[47px]'/>
         </div>

        </div>
        </div>


        <div className='fbs mt-[278px]'>
         <div className='flex flex-row w-full h-[580px] fsbanner' style={crmbanner}>
            <div className='flex flex-col w-[50%] h-[580px] '></div>
            <div className='flex flex-col w-[50%] h-[580px] '>
            <div className='text-[43px] text-white pt-[182px] ml-[82px] leading-[57px] w-full JekoDEMO-Medium empowerftr'>
            Efficient <span className='jeko-extrabold'>Tracking of leads <br></br>
            <span className="font-fallback  mt-[19px] ml-[9px] mr-[9px]">&</span >Conversation</span> using
</div>
               <div className='text-[48px] jeko-extrabold text-[#17A86D] ml-[82px]'>CRM Today</div>
               <div className='flex flex-row gap-5 ml-[82px] mt-[-32px]'>
               <div className='text-[20px] Kollektif-Regular font-normal text-[#0C0C0C] w-[177px] h-[42px] bg-[#ECCA81] rounded-[7px] mt-[48px] flex items-center justify-center '>View Pricing<span><img src={blackarrow} className='pl-[12px]'/></span></div>
               <div className='text-[20px] Kollektif-Regular font-normal text-[#0C0C0C] w-[177px] h-[42px] bg-[#FFFFFF] rounded-[7px] mt-[48px] flex items-center justify-center '>Contact Sales<span><img src={blackarrow} className='pl-[12px]'/></span></div>
               </div>
               <div className='text-[12px] text-white ml-[92px] mt-[12px]'>Get ready to be amazed<br></br> in 
               less than 45 minutes</div>
            </div>
         </div>
        </div>


        <div>
          <Footer/>
        </div>


    </div>
  )
}

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import CrmNew from './components/crm';
import CrmNe from './components/crm';
import FbsNew from './components/fbsNew';


// Lazy loading components
const Landing = React.lazy(() => import('./components/landing'));
const Scm = React.lazy(() => import('./components/scm'));
const Mms = React.lazy(() => import('./components/mms'));
const Fbs = React.lazy(() => import('./components/fbs'));
const Crm = React.lazy(() => import('./components/crmold'));
const ContactUs = React.lazy(() => import('./components/contactUs'));
const LandingMb = React.lazy(() => import('./components/landingMb'));
const FbsMobile = React.lazy(() => import('./components/fbsMobile'));
const ScmMobile = React.lazy(() => import('./components/scmMobile'));
const MmsMobile = React.lazy(() => import('./components/mmsMobile'));
const CrmMobile = React.lazy(() => import('./components/crmMobile'));
const ContactMobile = React.lazy(() => import('./components/contactMobile'));
const AllProducts = React.lazy(() => import('./components/allProducts'));
const Customer = React.lazy(() => import('./components/Customer'));
const AllProductsMobile = React.lazy(() => import('./components/allProductsMobile'));
const Hrm = React.lazy(() => import('./components/hrm'));
const Pos = React.lazy(() => import('./components/pos'));
const Fas = React.lazy(() => import('./components/fas'));
const Tasks = React.lazy(() => import('./components/tasks'));
const Filemanagement = React.lazy(() => import('./components/filemanagement'));
const Fms = React.lazy(() => import('./components/fms'));
const HrmMobile = React.lazy(() => import('./components/hrmMobile'));
const PosMobile = React.lazy(() => import('./components/posMobile'));
const FasMobile = React.lazy(() => import('./components/fasMobile'));
const TaskMobile = React.lazy(() => import('./components/tasksMobile'));
const FileManagementMobile = React.lazy(() => import('./components/fileManagementMobile'));
const FmsMobile = React.lazy(() => import('./components/fmsMobile'));
const CustomerMobile = React.lazy(() => import('./components/customerMobile'));
const Com = React.lazy(() => import('./components/com'));
const ComMobile = React.lazy(() => import('./components/comMobile'));
const PricingFbs = React.lazy(() => import('./components/pricingFbs'));
const Carrier = React.lazy(() => import('./components/carrier'));
const JobDescription = React.lazy(() => import('./components/jobDescription'));
const JobForm = React.lazy(() => import('./components/jobForm'));
const Partners = React.lazy(() => import('./components/partners'));
const CareersMobile = React.lazy(() => import('./components/careersMobile'));
const JobDescriptionmbile = React.lazy(() => import('./components/jobDescriptionmbile'));
const PartnersMobile = React.lazy(() => import('./components/partnersMobile'));
const PartnerDirectory = React.lazy(() => import('./components/solutionPartners'));
const Blog = React.lazy(() => import('./components/blogs'));
const LandingTest = React.lazy(() => import('./components/landingTest'));
const MainPage = React.lazy(() => import('./components/mainPage'));
const Carousel = React.lazy(() => import('./components/carsoul'));
const PricingFbsMobile = React.lazy(() => import('./components/pricingFbsMobile'));

const App = () => {
  return (
    <Router>
<Suspense fallback={null}>
<Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/landingmb" element={<LandingMb />} />
          <Route path="/crm" element={<CrmNe/>} />
          {/* <Route path="/crmnew" element={<CrmNew/>} /> */}
          <Route path="/crmb" element={<CrmMobile />} />
          <Route path="/scm" element={<Scm />} />
          <Route path="/scm_mb" element={<ScmMobile />} />
          <Route path="/mms" element={<Mms />} />
          <Route path="/mms_mb" element={<MmsMobile />} />
          <Route path="/fbs" element={<Fbs />} />
          <Route path="/fbsNew" element={<FbsNew/>} />
          <Route path="/fbsmb" element={<FbsMobile />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/contactmb" element={<ContactMobile />} />
          <Route path="/allproduct" element={<AllProducts />} />
          <Route path="/allproductmb" element={<AllProductsMobile />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/customer_mb" element={<CustomerMobile />} />
          <Route path="/hrm" element={<Hrm />} />
          <Route path="/hrmmobile" element={<HrmMobile />} />
          <Route path="/pos" element={<Pos />} />
          <Route path="/pos_mobile" element={<PosMobile />} />
          <Route path="/fas" element={<Fas />} />
    
          <Route path="/fasmobile" element={<FasMobile />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/tasksmobile" element={<TaskMobile />} />
          <Route path="/filemangement" element={<Filemanagement />} />
          <Route path="/filemangementmb" element={<FileManagementMobile />} />
          <Route path="/fms" element={<Fms />} />
          <Route path="/fmsmobile" element={<FmsMobile />} />
          <Route path="/com" element={<Com />} />
          <Route path="/com_mb" element={<ComMobile />} />
          <Route path="/pricingfbs" element={<PricingFbs />} />
          <Route path="/pricingfbsmobile" element={<PricingFbsMobile />} />
          <Route path="/carrier" element={<Carrier />} />
          <Route path="/carriermb" element={<CareersMobile />} />
          <Route path="/jobdescription" element={<JobDescription />} />
          <Route path="/jobdescriptionmobile" element={<JobDescriptionmbile />} />
          <Route path="/jobform" element={<JobForm />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/partnersmb" element={<PartnersMobile />} />
          <Route path="/solutionpartners" element={<PartnerDirectory />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/landtest" element={<LandingTest />} />
          <Route path="/carsul" element={<Carousel />} />
          
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
import React from 'react'
import Navbar from './navbar'
import mmswave1 from './assets/mmswave.svg'
import TabSection from './mmstab';
import fbslogo from './assets/fbsbrand_logo.svg'
import whitearrowcircle from './assets/whitearrow_circle.svg'
import whitearrow from './assets/whiterrow.svg'
import fbsbanner from './assets/Fbs_banner_new.png'
import keywheel1 from './assets/key-wheel.svg'
import keytick from './assets/key-tick.svg'
import fas from './assets/fas.svg'
import CRM from './assets/crmicon.svg'
import planning from './assets/plan.svg'
import purchase from './assets/purchaseicon.svg'

import redarrow from './assets/redarrow.svg'
import greensarrow from './assets/greendivert.svg'
import hashline from './assets/hashlinewider.svg'
export default function FbsNew() {

    const mmswave = {
        backgroundImage: `url(${mmswave1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    
      };


  return (
    <div className='main overflow-hidden'>
      <div>
        <Navbar/>
      </div>
      <div className='conatiner'>
      <div
  className="flex flex-row w-full h-[378px] items-center px-8 py-4 merchandising"
  style={mmswave}
>
  {/* Left Section with Logo and Text */}
  <div className="flex flex-row items-center w-[50%] ml-[98px] mt-[-17px]">
    {/* Logo */}
    <div className="flex-shrink-0">
      <img src={fbslogo} className="" alt="Logo" />
    </div>
    {/* Text */}
    <div className="ml-[8px]">
      <div className="text-[32px] Redhatbold text-black">Factory</div>
      <div className="text-[32px] RedHatDisplayMedium text-[#171717] mt-[-11px]">
      Business Suite
      </div>
    </div>
  </div>

  {/* Right Section with Fixed Tabs */}
  <div className="flex w-[50%] justify-end items-center">
    <div
      className="fixed right-1 top-16 z-40" // Adjust the position here as needed
      style={{ width: '300px' }} // Set a width to prevent layout issues
    >
      <TabSection/>
    </div>
  </div>
</div>
      </div>




      <div className='section1 w-full h-[1112px] mt-[377px] ' >
         <div className='text-[54px] text-[#323232] flex items-center justify-center tracking-[-0.01em] RedHatDisplayMedium mt-[-578px] inegratedfmtext'> Integrated Apparel factory management  </div>
         <div className='text-[51px] text-[#323232] flex items-center justify-center tracking-[-0.01em] leading-[49px] RedHatDisplayMedium '>that works on a <span className='text-[#147C5A] ml-[12px] Redhatbold'>4 Point Planning System</span>
         </div>
         <div className='w-[791.14px] flex text-center items-center justify-center mx-auto text-[19px] Ginóra text-[#292929] mt-[22px] tracking-[-0.01em] GinóraSemiBold'>Introducing FIT-FBS (Factory Business Suite), a comprehensive ERP solution expertly crafted for manufacturers and exporters looking to automate their business operations with ease.</div>
         <div className='flex '>
                  <div className='text-[19px] Kollektif-Regular font-normal text-white w-[157px] h-[44px] bg-[#474747] rounded-[7px] mt-[48px] flex items-center justify-center '>See pricing <span><img src={whitearrow} className='pl-[12px]'/></span></div>
                  <div className='text-[19px] GinóraSemiBold text-[#2B2B2B] mt-[48px] ml-[28px] flex items-center justify-center'>Request a Demo</div>
                  <img src={whitearrowcircle} className='flex items-center justify-center pt-[48px] ml-[17px]'/>
               </div>
                  
               <img src={fbsbanner} className='w-[1647px] h-[672px] mt-[48px] fbsbanner'/>
               {/* <div className='text-[24px] GinóraSemiBold text-center mt-[90px]'>Trusted by global brands</div>
               <img src={brands} className='flex items-center justify-center mx-auto mt-[48px] '/>
            <div className='flex items-center justify-center mx-auto mt-[90px]'><img src={divider}/></div> */}
            </div>




            <div className="keyfeatures w-[79%]  h-[417px] mx-auto mt-[-177px]">
  <div className="grid grid-cols-2">
    {/* Black Box with Reduced Width */}
    <div className="w-full h-[717px] mx-auto" >
      <div className='contentbx' >
        <img src={keywheel1} className='z-40'/>
        <div className='text-[42px] Redhatsemibold text-black ml-[147px] mt-[-147px]' >key Features</div>
        <div className='quicksand-medium text-[15px] text-[#202020] w-[397px] ml-[147px]'>
          Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus sollicitudin lacus, ut interdum tellus elit sed risus. 
        </div>
      </div>
      <div className='text-[42px] text-[#202020] Redhatsemibold ml-[147px] mt-[247px]'>
        Integrated <span className='RedHatDisplayRegular'>Add-Ons</span>
      </div>
    </div>
<div className='bg-[#FAFAFA] border border-[#E0E0E0] rounded-[21px]'>
    {/* Grey Box */}
    <div className="w-full h-[417px] ">
      {/* Grid with Two Boxes */}
      <div className="grid grid-cols-2 mt-[47px]">
        {/* Left Red Box */}
        <div className="w-full h-[417px] p-4">
          <ul className="space-y-4">
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
              <span className="mr-2"><img src={keytick}/></span> Enquiry, Costing & Quotations
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Sample/Bulk Order Management
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Material Requirement Planning
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Budget & Actuals
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Time & Action Scheduler
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span>Financial Accounting
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Dashboard & Analytics
            </li>
          </ul>
        </div>

        {/* Slate Box */}
        <div className="w-full h-[417px]  p-4">
          <ul className="space-y-4 ">
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Purchases, Stores & Inventory
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Process Flow Management
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Daily Production & Closure
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Shipment & Documentation
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> Multilevel Approvals
            </li>
            <li className="flex items-center text-[18px] RedHatDisplayMedium">
            <span className="mr-2"><img src={keytick}/></span> MIS Query Reporting
            </li>
          </ul>
        </div>
      </div>

      {/* Bottom Grid */}
      <div className='grid grid-cols-2 '>

        <div className='w-full h-[247px] boxone border-r border-black'>
          <div className='text-[27px] text-[#121212] RedHatDisplayRegular flex ml-[47px]'>
            <span className="mr-2 w-[50px] h-[50px]"><img src={fas} /></span>FAS
          </div>
          <div className='text-[12px] Quicksand text-[#202020] ml-[47px] w-[284px] mt-[24px]'>
            Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.
          </div>
          <div className='items-center flex'>
            <div className='flex quicksand-medium text-[17px] text-[#EE343C] ml-[47px] mt-[17px]'>
              Explore <span className="mr-2 w-[50px] h-[50px]">
                <img src={redarrow} className='mt-[7px] ml-[11px]' />
              </span>
            </div>
          </div>
        </div>

        <div className='w-full h-[247px] '>
          <div className='text-[27px] text-[#121212] RedHatDisplayRegular flex ml-[47px]'>
            <span className="mr-2 w-[50px] h-[50px]"><img src={CRM} /></span>CRM
          </div>
          <div className='text-[12px] Quicksand text-[#202020] ml-[47px] w-[284px] mt-[24px]'>
            Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.
          </div>
          <div className='items-center flex'>
            <div className='flex quicksand-medium text-[17px] text-[#17A86D] ml-[47px] mt-[17px]'>
              Explore <span className="mr-2 w-[50px] h-[50px]">
                <img src={greensarrow} className='mt-[7px] ml-[11px]' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>


<img src={hashline} className='flex mx-auto mt-[447px]'/>
       

       <div className='section2 mt-[77px]' >
        <div className='text-[43px] text-[#1A1A1A] w-[786px] flex mx-auto items-center justify-center text-center leading-[104%] CirceBold'>We’re on a mission to help understand with each concept.</div>
        <div className='flex items-center justify-center mx-auto text-center w-[822px] mt-[18px]'>FITTEX Merchandising Management System (MMS) is the ultimate solution for buying houses and sourcing operations. MMS offers a seamless environment for smooth collaboration between offices and suppliers. With a dynamic time and action calendar, integrated style banks, and streamlined order tracking, FITTEX MMS simplifies everything from sampling to shipments, ensuring efficiency at every step.
        </div>
        
       </div>


      <div className='planningTb conatiner mx-auto mt-[47px] w-full h-[177px]'>

      <div className="flex justify-center gap-4 mt-4 bg-transparent">
  {/* Planning & Allocation */}
  <div className="flex flex-col items-center">
    <div className="flex flex-row w-[276px] h-[63.73px] items-center justify-start rounded-full overflow-hidden">
      <div className="bg-[#148460] w-[60px] h-full flex items-center justify-center">
        <img src={planning} className="h-6 w-6" />
      </div>
      <div className="bg-[#1CB584]  text-white text-[18px]  flex-1 h-full flex items-center justify-center">
        Planning & Allocation
      </div>
    </div>
  </div>

  {/* Purchase Management */}
  <div className="flex flex-col items-center">
    <div className="flex flex-row w-[276px] h-[63.73px] items-center justify-start rounded-full overflow-hidden">
      <div className="bg-[#DBDBDB] w-[60px] h-full flex items-center justify-center">
        <img src={purchase} className="h-6 w-6" />
      </div>
      <div className="bg-[#E9E9E9] text-[#282828] text-[18px] flex-1 h-full flex items-center justify-center">
        Purchase Management
      </div>
    </div>
  </div>

  {/* Stores & Inventory */}
  <div className="flex flex-col items-center">
    <div className="flex flex-row w-[276px] h-[63.73px] items-center justify-start rounded-full overflow-hidden">
      <div className="bg-[#DBDBDB] w-[60px] h-full flex items-center justify-center">
        {/* <img src={store} className="h-6 w-6" /> */}
      </div>
      <div className="bg-[#E9E9E9] text-[#282828] text-[18px]  flex-1 h-full flex items-center justify-center">
        Stores & Inventory
      </div>
    </div>
  </div>

  {/* Process & Production */}
  <div className="flex flex-col items-center">
    <div className="flex flex-row w-[276px] h-[63.73px] items-center justify-start rounded-full overflow-hidden">
      <div className="bg-[#DBDBDB] w-[60px] h-full flex items-center justify-center">
        <img src={process} className="h-6 w-6" />
      </div>
      <div className="bg-[#E9E9E9] text-[#282828] text-[18px]  flex-1 h-full flex items-center justify-center">
        Process & Production
      </div>
    </div>
  </div>
</div>
      </div>






    </div>
  )
}

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Import the icon images
import activeTshirt from './assets/mmsicongreener.svg';  // Replace with actual path
import inactiveTshirt from './assets/mmsinactive.svg'; // Replace with actual path
import activeTree from './assets/activeTree.svg';  // Replace with actual path
import inactiveTree from './assets/inactiveTree.svg'; // Replace with actual path
import activeBox from './assets/activeBox.svg';  // Replace with actual path
import inactiveBox from './assets/inactiveBox.svg'; // Replace with actual path
import activeCRM from './assets/activeCRM.svg';  // Replace with actual path
import inactiveCRM from './assets/inactiveCRM.svg'; // Replace with actual path

const TabSection = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const location = useLocation(); // Get the current location

  const tabs = [
    { 
      id: 'MMS', 
      name: 'MMS', 
      activeIcon: activeTshirt,   
      inactiveIcon: inactiveTshirt,
      link: '/mms', // Path to navigate to
      hasRoundedEdge: true // Control the rounded edge for the first tab
    },
    { 
      id: 'FBS', 
      name: 'FBS', 
      activeIcon: activeTree, 
      inactiveIcon: inactiveTree,
      link: '/fbs' // Path to navigate to
    },
    { 
      id: 'SCM', 
      name: 'SCM', 
      activeIcon: activeBox, 
      inactiveIcon: inactiveBox,
      link: '/scm' // Path to navigate to
    },
    { 
      id: 'CRM', 
      name: 'CRM', 
      activeIcon: activeCRM, 
      inactiveIcon: inactiveCRM,
      link: '/crm' // Path to navigate to
    }
  ];

  // Determine the active tab based on the current path
  const currentPath = location.pathname;
  const activeTab = tabs.find(tab => tab.link === currentPath)?.id || 'MMS';

  const handleTabClick = (tabId, link) => {
    navigate(link); // Navigate to the specified link
  };

  return (
    <div className="flex bg-white rounded-[42px] shadow-lg w-max">
      {tabs.map((tab, index) => (
        <div
          key={tab.id}
          onClick={() => handleTabClick(tab.id, tab.link)}
          className={`flex flex-col items-center justify-center w-24 h-24 cursor-pointer transition-colors duration-300
            ${activeTab === tab.id ? 'bg-green-500 text-white' : 'text-green-500'} 
            ${index === 0 ? (activeTab === tab.id ? 'rounded-l-full' : 'rounded-l-full bg-transparent') : ''}
            ${index === tabs.length - 1 ? 'rounded-r-full' : ''}
            ${activeTab !== tab.id ? 'bg-transparent' : ''} 
            mx-1`}
        >
          {/* Icon */}
          <img 
            src={activeTab === tab.id ? tab.activeIcon : tab.inactiveIcon} 
            alt={tab.name} 
            className="w-8 h-8"
          />
          {/* Tab Title */}
          <span className="font-semibold">{tab.name}</span>
        </div>
      ))}
    </div>
  );
};

export default TabSection;